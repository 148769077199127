<template>
  <div class="main_page">
    <app-navbar></app-navbar>
    <div class="page">
      <div class="page-title">
        <h1>Payment Report</h1>
      </div>
      <div class="page-content">
        <form @submit.prevent="onSubmitForm">
          <div class="form-row">
            <div class="form-group col-md-6 mb-3">
              <label for="purchasingCompany">Purchasing Company</label>
              <input type="text" class="form-control" id="purchasingCompany" placeholder="Company Name" v-model="search.purchasingCompany">
            </div>
            <div class="form-group col-md-6 mb-3">
              <label for="projectId">Project Title</label>
              <input type="text" class="form-control" id="projectId" placeholder="Project Title" v-model="search.projectTitle" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6 mb-3">
              <label for="orderId">Order Id</label>
              <input id="orderId" name="orderId" type="text" class="form-control" :class="{ 'is-invalid': errors.has('orderId') }"
                autocomplete="off" placeholder="eg. 00000000-0000-0000-0000-000000000000" v-model="search.orderId" v-validate="{ regex: regexEmptyGuid }" />
              <div class="invalid-feedback">The Order Id format is invalid (eg. 00000000-0000-0000-0000-000000000000)</div>
            </div>
            <div class="form-group col-md-6 mb-3">
              <label for="paymentType">Payment Type</label>
              <div class="input-group">
                <select id="paymentType" class="form-control custom-select" v-model="search.paymentType">
                  <option value="MASTERCARD">Mastercard</option>
                  <option value="VISA">Visa</option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-sm btn-danger" type="button" aria-label="Delete saved search" @click.prevent="search.paymentType = null">
                    <i class="far fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6 mb-3">
              <label for="dateFrom">Date From</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent pr-1">
                    <img src="@/assets/cal.svg" alt />
                  </span>
                </div>
                <date-picker id="dateFrom" class="datepickerfrom form-control border-left-0" autocomplete="off" :config="datepickerOptions" v-model="search.dateFrom" />
              </div>
            </div>
            <div class="form-group col-md-6 mb-3">
              <label for="dateTo">Date To</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent pr-1">
                    <img src="@/assets/cal.svg" alt />
                  </span>
                </div>
                <date-picker id="dateTo" class="datepickerfrom form-control border-left-0" autocomplete="off" :config="datepickerOptions" v-model="search.dateTo" />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-primary ld-ext-right ml-2" :class="{ 'running' : isDownloading }" :disabled="isDownloading">
              <span v-if="isDownloading">Exporting...</span>
              <span v-else>Export to Excel</span>
              <div class="ld ld-ring ld-spin"></div>
            </button>
            <button type="button" class="btn btn-warning ml-2" @click="clearForm">Clear</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-content {
  height: 750px;
}
</style>

<script>
import Toast from "@/utils/toast";
import FileDownload from "@/utils/fileDownload";
import { PaymentService } from "@/services/";

export default {
  name: "configuration-payments",
  components: {
    "app-navbar": () => import('@/components/app-navbar.vue'),
    "date-picker": () => import("vue-bootstrap-datetimepicker")
  },
  data: function() {
    return {
      isPageLoading: true,
      isDownloading: false,
      search: {
        purchasingCompany: null,
        paymentType: null,
        projectTitle: null,
        orderId: null,
        dateFrom: null,
        dateTo: null
      },
      datepickerOptions: {
        useCurrent: false,
        format: "MM/DD/YYYY"
      }
    };
  },
  computed: {
    regexEmptyGuid () {
      return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    }
  },
  methods: {
    clearForm () {
      this.search.purchasingCompany = null;
      this.search.paymentType = null;
      this.search.projectTitle = null;
      this.search.orderId = null;
      this.search.dateFrom = null;
      this.search.dateTo = null;
    },
    async onSubmitForm () {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isDownloading = true;

      PaymentService.export({ search: { ...this.search } })
        .then(response => {
          Toast.success(this, "Export successful!");
          this.isDownloading = false;
          FileDownload("PaymentExport.xlsx", response.data);
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.isDownloading = false;
        });
    }
  }
};
</script>
